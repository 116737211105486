<template>
  <div class="card-body p-9 px-0">
    <div
      class="row"
      v-for="(item, index) in data"
      :key="index"
      :class="{'mb-7' : index !== data.length - 1}"
    >
      <label class="col-lg-4 data__label">
        {{ item.label }}
      </label>
      <div class="col-lg-8">
        <span class="fw-bolder data__value">
          {{ item.value }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompanyDetails",

  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.data {
  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8B8B8B;
  }
  &__value {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #040404;
  }
}
</style>
