<template>
    <div class="row g-5 g-xxl-8 mx-0">
        <div class="card mb-5 mb-xl-10 py-2" id="kt_profile_details_view">
            <div class="card-header cursor-pointer px-0">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">{{ $t("Company Details") }}</h3>

                    <router-link
                        to="/bank/settings/bank-settings/settings"
                        class="edit-btn"
                        v-if="currentUser.can(BankUserPermissionTypeEnum.EDIT_BANK_SETTINGS)"
                    >
                        <img height="15" width="14" class="me-3" src="/media/buying/icons/edit.svg" alt="">
                        {{ $t("Edit profile") }}
                    </router-link>
                </div>
            </div>
            <CompanyDetails
                v-if="bankData"
                :data="bankData"
            />
        </div>
    </div>
</template>
<script>
import CompanyDetails from "@/buying-teams/shared-components/settings/CompanyDetails";
import { BankSettingsContainer } from "@/store/models/bank/BankSettingsContainer";
import store from "@/store";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "Overview",

    data() {
        return {
            BankUserPermissionTypeEnum
        }
    },

    props: {
        bankDetails: BankSettingsContainer
    },

    components: {
        CompanyDetails
    },

    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
        bankData() {
            return [
                {
                    label: this.$t("Bank Name"),
                    value: this.bankDetails.bankInfo.name
                },
                {
                    label: this.$t("Countries of the Bank"),
                    value: this.bankDetails.countriesBank.length > 4
                        ? this.bankDetails.countriesBank.slice(0, 4).join(", ") + " +" + (this.bankDetails.countriesBank.length - 4)
                        : this.bankDetails.countriesBank.join(", ")
                },
                {
                    label: this.$t("Number of Users"),
                    value: this.bankDetails.usersNumber
                },
                {
                    label: this.$t("Number of Customers"),
                    value: this.bankDetails.customersNumber
                }
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
.card-title {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .edit-btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #435BF4;
        display: flex;
        align-items: center;
    }
}
</style>
